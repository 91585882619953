@font-face {
  font-family: Graphik;
  src: url('./assets/fonts/graphik/Graphik-Regular-Web.woff');
}

@font-face {
  font-family: 'Graphik Semibold';
  src: url('./assets/fonts/graphik-semi-bold/Graphik-Semibold-Web.woff2') format('woff2'),
       url('./assets/fonts/graphik-semi-bold/Graphik-Semibold-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: monotype-grotesque-display-e;
  src: url('https://use.typekit.net/znp4ygi.css');
}

*::-webkit-scrollbar {
  display: none
}

* {
  scrollbar-width: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  background: #ebebeb;
  overflow: hidden;
}
